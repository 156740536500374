* {
    font-family: var(--chakra-fonts-body);
}

.animated-gradient {
    overflow: hidden;
    background: linear-gradient(298deg, #3f37c9, #322ca0, #010400, #560bad);
    background-size: 800% 800%;
    -webkit-animation: gradient 8s ease infinite;
    -moz-animation: gradient 8s ease infinite;
    animation: gradient 8s ease infinite;
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 77%;
    }
    50% {
        background-position: 100% 24%;
    }
    100% {
        background-position: 0% 77%;
    }
}
@-moz-keyframes gradient {
    0% {
        background-position: 0% 77%;
    }
    50% {
        background-position: 100% 24%;
    }
    100% {
        background-position: 0% 77%;
    }
}
@keyframes gradient {
    0% {
        background-position: 0% 77%;
    }
    50% {
        background-position: 100% 24%;
    }
    100% {
        background-position: 0% 77%;
    }
}
